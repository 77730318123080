export const turkishAirports = [
  { value: "IST", label: "İstanbul (IST)" },
  { value: "ANK", label: "Ankara (ANK)" },
  { value: "ADB", label: "İzmir (ADB)" },
  { value: "ESB", label: "Eskişehir (ESB)" },
  { value: "ADA", label: "Adana (ADA)" },
];

export const mockFlights = [
  {
    id: 1,
    departureAirport: "IST",
    arrivalAirport: "ANK",
    departureTime: "2024-06-10T10:45",
    arrivalTime: "2024-06-10T13:55",
    duration: "9h 10m",
    price: 1200,
    airline: "Turkish Airlines",
  },
  {
    id: 2,
    departureAirport: "ESB",
    arrivalAirport: "ANK",
    departureTime: "2024-06-12T11:30",
    arrivalTime: "2024-06-13T07:45",
    duration: "11h 15m",
    price: 900,
    airline: "SunExpress",
  },
  {
    id: 3,
    departureAirport: "ADB",
    arrivalAirport: "IST",
    departureTime: "2024-06-14T15:00",
    arrivalTime: "2024-06-14T18:30",
    duration: "11h 30m",
    price: 1100,
    airline: "Pegasus Airlines",
  },
  {
    id: 4,
    departureAirport: "ADA",
    arrivalAirport: "ESB",
    departureTime: "2024-06-16T20:20",
    arrivalTime: "2024-06-17T08:35",
    duration: "9h 15m",
    price: 950,
    airline: "Turkish Airlines",
  },
  {
    id: 5,
    departureAirport: "IST",
    arrivalAirport: "ADA",
    departureTime: "2024-06-18T17:00",
    arrivalTime: "2024-06-19T01:00",
    duration: "14h",
    price: 1300,
    airline: "AnadoluJet",
  },
  {
    id: 6,
    departureAirport: "ANK",
    arrivalAirport: "ESB",
    departureTime: "2024-07-18T17:00",
    arrivalTime: "2024-07-19T01:00",
    duration: "14h",
    price: 1300,
    airline: "SunExpress",
  },
  {
    id: 7,
    departureAirport: "ESB",
    arrivalAirport: "IST",
    departureTime: "2024-06-18T17:00",
    arrivalTime: "2024-06-19T01:00",
    duration: "14h",
    price: 1300,
    airline: "Pegasus Airlines",
  },
  {
    id: 8,
    departureAirport: "IST",
    arrivalAirport: "ANK",
    departureTime: "2024-06-20T08:15",
    arrivalTime: "2024-06-20T09:30",
    duration: "1h 15m",
    price: 200,
    airline: "AnadoluJet",
  },
  {
    id: 9,
    departureAirport: "ESB",
    arrivalAirport: "ADB",
    departureTime: "2024-06-22T14:45",
    arrivalTime: "2024-06-22T16:00",
    duration: "1h 15m",
    price: 180,
    airline: "SunExpress",
  },
  {
    id: 10,
    departureAirport: "ADA",
    arrivalAirport: "IST",
    departureTime: "2024-06-25T18:30",
    arrivalTime: "2024-06-25T19:45",
    duration: "1h 15m",
    price: 250,
    airline: "Turkish Airlines",
  },
  {
    id: 11,
    departureAirport: "ANK",
    arrivalAirport: "ADB",
    departureTime: "2024-06-28T10:00",
    arrivalTime: "2024-06-28T11:15",
    duration: "1h 15m",
    price: 220,
    airline: "Pegasus Airlines",
  },
  {
    id: 12,
    departureAirport: "ESB",
    arrivalAirport: "IST",
    departureTime: "2024-07-01T14:30",
    arrivalTime: "2024-07-01T15:45",
    duration: "1h 15m",
    price: 190,
    airline: "Pegasus Airlines",
  },
  {
    id: 13,
    departureAirport: "IST",
    arrivalAirport: "ESB",
    departureTime: "2024-07-05T20:45",
    arrivalTime: "2024-07-05T22:00",
    duration: "1h 15m",
    price: 210,
    airline: "AnadoluJet",
  },
  {
    id: 14,
    departureAirport: "ESB",
    arrivalAirport: "IST",
    departureTime: "2024-07-01T15:30",
    arrivalTime: "2024-07-01T16:45",
    duration: "1h 15m",
    price: 300,
    airline: "SunExpress",
  },
  {
    id: 15,
    departureAirport: "IST",
    arrivalAirport: "ESB",
    departureTime: "2024-07-05T21:45",
    arrivalTime: "2024-07-05T23:00",
    duration: "1h 15m",
    price: 210,
    airline: "AnadoluJet",
  },
  {
    id: 16,
    departureAirport: "ESB",
    arrivalAirport: "IST",
    departureTime: "2024-07-01T18:30",
    arrivalTime: "2024-07-01T19:45",
    duration: "1h 15m",
    price: 200,
    airline: "Turkish Airlines",
  },
  {
    id: 17,
    departureAirport: "ESB",
    arrivalAirport: "IST",
    departureTime: "2024-07-01T18:30",
    arrivalTime: "2024-07-01T19:45",
    duration: "1h 15m",
    price: 200,
    airline: "AnadoluJet",
  },
  {
    id: 18,
    departureAirport: "ESB",
    arrivalAirport: "IST",
    departureTime: "2024-07-01T18:30",
    arrivalTime: "2024-07-01T19:45",
    duration: "1h 15m",
    price: 200,
    airline: "Pegasus Airlines",
  },
  {
    id: 19,
    departureAirport: "ESB",
    arrivalAirport: "IST",
    departureTime: "2024-07-01T18:30",
    arrivalTime: "2024-07-01T19:45",
    duration: "1h 15m",
    price: 200,
    airline: "SunExpress",
  },
  {
    id: 20,
    departureAirport: "ESB",
    arrivalAirport: "IST",
    departureTime: "2024-07-01T18:30",
    arrivalTime: "2024-07-01T19:45",
    duration: "1h 15m",
    price: 200,
    airline: "Turkish Airlines",
  },
  {
    id: 21,
    departureAirport: "ESB",
    arrivalAirport: "IST",
    departureTime: "2024-07-01T18:30",
    arrivalTime: "2024-07-01T19:45",
    duration: "1h 15m",
    price: 200,
    airline: "Pegasus Airlines",
  },
  {
    id: 22,
    departureAirport: "ESB",
    arrivalAirport: "IST",
    departureTime: "2024-07-01T18:30",
    arrivalTime: "2024-07-01T19:45",
    duration: "1h 15m",
    price: 200,
    airline: "Turkish Airlines",
  },
  {
    id: 23,
    departureAirport: "ESB",
    arrivalAirport: "IST",
    departureTime: "2024-07-01T18:30",
    arrivalTime: "2024-07-01T19:45",
    duration: "1h 15m",
    price: 200,
    airline: "Turkish Airlines",
  },
  {
    id: 24,
    departureAirport: "ESB",
    arrivalAirport: "IST",
    departureTime: "2024-07-01T18:30",
    arrivalTime: "2024-07-01T19:45",
    duration: "1h 15m",
    price: 200,
    airline: "Pegasus Airlines",
  },
  {
    id: 25,
    departureAirport: "ESB",
    arrivalAirport: "IST",
    departureTime: "2024-07-01T18:30",
    arrivalTime: "2024-07-01T19:45",
    duration: "1h 15m",
    price: 200,
    airline: "Turkish Airlines",
  },
];
